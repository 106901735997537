<template>
  <div class="home">
    <h1>Welcome to Noob Coin!</h1>
    <p>Noob Coin is the cryptocurrency for beginners and veterans alike.</p>
    <p>Join our community and embrace the fun of learning and growing together!</p>
    <div class="cta-buttons">
      <router-link to="/about" class="button">Learn More</router-link>
      <router-link to="/wallet" class="button">Get Started</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage'
}
</script>

<style scoped>
.home {
  text-align: center;
  margin-top: 50px;
}

.cta-buttons {
  margin-top: 20px;
}

.button {
  display: inline-block;
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.button:hover {
  background-color: #45a049;
}
</style>
